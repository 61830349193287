import React, { useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { css } from "@emotion/react"; // Import the loader
import { ClipLoader } from "react-spinners"; // Import the loader

// import loho from '../static/StoreY EMB green..png'
// import SignUTargetM from './SignUTargetM'

const SignUSForm = () => {
  const navigate = useNavigate();
  const { referral } = useParams();

  const [storeName, setStoreName] = useState("");
  const [storeType, setStoreType] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [avatar, setAvatar] = useState(null);
  const { setUserInfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const displayError = (error) => {
    let errorMessage =
      "Failed to register user. Please try again. Make sure you've not registered with this data before";

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error
    ) {
      errorMessage = error.response.data.error; // Access error message from response
    }

    toastr.error(errorMessage, "Registration Error");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("storeAddress", storeAddress);
      formData.append("storeName", storeName);
      formData.append("storeType", storeType);
      formData.append("city", city);
      formData.append("username", username);
      formData.append("phoneNumber", phoneNumber);
      formData.append("avatar", avatar[0]);
      formData.append("email", email);
      formData.append("password", password);
      if (referral) {
        formData.append("referral", referral);
      }
      // console.log('formData', avatar[0])
      const loginResponse = await axios({
        url: "/api/v1/store/",
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
        },
        // withCredentials: true,
        data: formData,
      });

      // Store the token in localStorage
      localStorage.setItem("token", loginResponse.data.token);

      const profileResponse = await axios({
        url: "api/v1/store/profile",
        // withCredentials: true,
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const userInfoData = profileResponse.data;
      setUserInfo(userInfoData);
      setIsLoading(false);
      // console.log(userInfoData);
      navigate("/myprofile");

      // console.log(profileResponse);
      // setRedirect(true);
    } catch (error) {
      console.log(error);
      displayError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    // <div className='signuSform'>
    //   <img src={loho} alt='StoreyApp logo' className='lohosm'/>
    //   <div>Welcome to StoreyApp</div>
    //   <SignUTargetM/>
    <div className="">
      <div
        className="loading-overlay"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={isLoading}
        />
        <p>Loading...</p>
      </div>
      <div className="sign_form">
        <form className="post_sign" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Store Name</label>
            <input
              type="text"
              onChange={(e) => setStoreName(e.target.value)}
              value={storeName}
              className="form_input form_inp"
              placeholder={"Your Store name"}
            />
          </div>
          <div className="form-group">
            <label>Store Type</label>
            <select
              name="storeType"
              value={storeType || ""}
              onChange={(e) => setStoreType(e.target.value)}
              className="form-control"
            >
              <option value="">Select Company Type</option>
              <option value="Pharmacy">Pharmacy</option>
              <option value="FMCG">FMCG</option>
              <option value="Supermarket">Supermarket</option>
              <option value="Agrochemical store">Agrochemical store</option>
              <option value="Groceries store">Groceries store</option>
            </select>
          </div>
          <div className="waitlist_post">
            <label className="form_label">Store Address</label>
            <input
              type="text"
              onChange={(e) => setStoreAddress(e.target.value)}
              value={storeAddress}
              className="form_input form_inp"
              placeholder={"Enter the address to you Store here"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">City</label>
            <input
              type="text"
              onChange={(e) => setCity(e.target.value)}
              value={city}
              className="form_input form_inp"
              placeholder={"Enter your city of Store operation here"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Username</label>
            <input
              type="test"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              className="form_input form_inp"
              placeholder={"Enter your username here"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Phone Number</label>
            <input
              type="text"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
              className="form_input form_inp"
              placeholder={"Enter your correct phone number here"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form_input form_inp"
              placeholder={"Your email"}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                className="form_input form_inp"
                placeholder={"Enter your password here"}
              />
              <button
                className="showbutton"
                type="button"
                onClick={handleTogglePassword}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <div className="waitlist_post">
            <label className="form_label">Display Image</label>
            <input
              type="file"
              onChange={(e) => setAvatar(e.target.files)}
              className="custom-file-input"
              accept="image/*"
            />
            {/* <input type="file" onChange={(e) => setAvatar(e.target.files[0])} className="custom-file-input"
              accept="image/*" /> */}
            <br />

            <div className="image-preview-container">
              {avatar &&
                Array.from(avatar).map((image, index) => (
                  <div key={index} className="image-preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Images ${index}`}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="tandcConatiner">
            <p>
              By submitting this form you are accepting{" "}
              <Link to="/tandc" className="tandc">
                <b>FarmyApp Terms of Use</b>
              </Link>
            </p>
          </div>
          <button className="sign_bt" disabled={isLoading}>
            {isLoading ? <span className="loading-spinner"></span> : "Submit"}
          </button>
        </form>
        <div className="already1">
          Already have an account?{" "}
          <Link to="/signin" className="signalt">
            Log in
          </Link>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default SignUSForm;
