import { Link } from "react-router-dom";
import React, { useContext } from "react";

import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { BsPerson, BsCart2 } from "react-icons/bs";
// import { BiLogOut } from "react-icons/bi";
import { AiOutlineCar } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BiLogoFacebookSquare, BiLogoLinkedinSquare } from "react-icons/bi";
import { GoOrganization } from "react-icons/go";
import { UserContext } from "../context/UserContext";
import { IoLogoAndroid } from "react-icons/io";

import {
  AiFillTwitterSquare,
  AiFillInstagram,
  AiFillApple,
} from "react-icons/ai";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default function Footer() {
  const { userInfo } = useContext(UserContext);
  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };

  const isAuthenticated = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return !!userInfo && userInfo.username;
  };

  return (
    <div className="footer">
      <div className="footer1">
        <div className="">
          <Link className="Mmenuitem1" to="/stores">
            <MdStoreMallDirectory className="Mmenuicon1" size={20} />
            <div className="Mmenuident1">Search Store</div>
          </Link>
          <Link className="Mmenuitem1" to="/company">
            <GoOrganization className="Mmenuicon1" size={20} />
            <div className="Mmenuident1">Whole sellers</div>
          </Link>
          <Link className="Mmenuitem1" to="/farmersmarket">
            <MdAgriculture className="Mmenuicon1" size={20} />
            <div className="Mmenuident1">Farmers' Market</div>
          </Link>
          <Link className="Mmenuitem1" to="/logistics">
            <AiOutlineCar className="Mmenuicon1" size={20} />
            <div className="Mmenuident1">Logistics</div>
          </Link>
          {isAuthenticated() && (
            <>
              <div className="Mmenuitem1" onClick={notify}>
                <BsCart2 className="Mmenuicon1" size={20} />
                <div className="Mmenuident1">My Cart</div>
              </div>
              <div className="Mmenuitem1" onClick={notify}>
                <IoMdNotificationsOutline className="Mmenuicon1" size={20} />
                <div className="Mmenuident1">Notification</div>
              </div>
              <div className="Mmenuitem1" onClick={notify}>
                <BsPerson className="Mmenuicon1" size={20} />
                <div className="Mmenuident1">Profile</div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="footer2">
        <h3> Download FarmyApp App here </h3>
        <div className="appContainer2">
          <a
            href={
              "https://play.google.com/store/apps/details?id=com.paade.farmyapp" ||
              "#"
            }
            target={
              "https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                ? "_blank"
                : "_self"
            }
            rel="noopener noreferrer"
            className="download-button__link"
            onClick={(e) => {
              if (
                !"https://play.google.com/store/apps/details?id=com.paade.farmyapp"
              ) {
                e.preventDefault();
                console.warn("No URL provided for this button.");
              }
            }}
          >
            <div className="eachIcon1" onClick={notify}>
              {" "}
              <IoLogoAndroid size="40px" /> <span> Get on Android</span>
            </div>
          </a>
          <div className="eachIcon1" onClick={notify}>
            {" "}
            <AiFillApple size="40px" /> <span> Get on iPhone</span>
          </div>
        </div>
        <p className="wewill">Follow us on social media</p>
        <div className="socials">
          <a href="https://www.facebook.com/FarmyAppAgriculture">
            <BiLogoFacebookSquare color="white" />
          </a>
          <a href="https://www.linkedin.com/company/farmyapp/?viewAsMember=true">
            <BiLogoLinkedinSquare color="white" />
          </a>
          <a href="https://instagram.com/farmyapp?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D">
            <AiFillInstagram color="white" />
          </a>
          <a href="https://twitter.com/farmyapp?t=Hn0NSoQsuFFiUXiGuYBprw&s=08">
            <AiFillTwitterSquare color="white" />
          </a>
        </div>
      </div>
      <div className="footer3">
        <h4>
          For more information contact <br /> info@farmyapp.com or <br />
          farmyapp@gmail.com <br />
          +2348110175635, +2348110175793, <br />
          +2348110175727
        </h4>
      </div>
    </div>
  );
}
