// src/components/Dashboard.js
import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import QRCode from "qrcode.react";
import "../styles/dashboard.css";
import { LuLayoutDashboard } from "react-icons/lu";
import { PiWalletThin } from "react-icons/pi";
import { FcSalesPerformance, FcScatterPlot } from "react-icons/fc";
import { AiOutlineCar, AiOutlineCopy } from "react-icons/ai";
import { VscReferences } from "react-icons/vsc";
import { Link } from "react-router-dom";
import DeleteAccountAndChats from "./DeleteAccountAndChats";
import RegistrationProgress from "./RegistrationProgress";

export default function Dashboard() {
  const { userInfo } = useContext(UserContext);
  const abbreviateLink = (originalLink, maxLength) => {
    if (originalLink.length <= maxLength) {
      return originalLink;
    }
    return `${originalLink.substring(0, maxLength)}...`;
  };

  let referralURL;
  let linkText;

  if (userInfo.storeName && userInfo.slug) {
    referralURL = `${window.location.origin}/store/${userInfo.slug}`;
    linkText = "Your Store Link";
  } else if (userInfo.logisticsName && userInfo.slug) {
    referralURL = `${window.location.origin}/logistics/${userInfo.slug}`;
    linkText = "Your Logistics Link";
  } else if (userInfo.companyName && userInfo.slug) {
    referralURL = `${window.location.origin}/company/${userInfo.slug}`;
    linkText = "Your Company Link";
  } else {
    referralURL = `${window.location.origin}/signup/${userInfo._id}`;
    linkText = "Referral Link";
  }

  let abbreviatedReferralURL = abbreviateLink(referralURL, 35);

  const copyReferralLink = () => {
    navigator.clipboard
      .writeText(referralURL)
      .then(() => {
        alert("Referral link copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying to clipboard: ", error);
      });
  };

  return (
    <div className="dashboard">
      <h3 className="dashboardTitle">
        <LuLayoutDashboard /> My Dashboard
      </h3>
      <RegistrationProgress userInfo={userInfo} />
      <div className="topDashboard">
        <div className="eachDash">
          <div>
            <PiWalletThin size={20} /> Wallet Balance
          </div>
          <h3>&#x20A6;{userInfo.wallet?.finalBalance}</h3>
        </div>
        <div className="eachDash">
          <div>
            <FcSalesPerformance size={20} /> {userInfo.companyName ? 'Total Commission' : 'Temporary Balance'}
          </div>
          <h3>&#x20A6;{userInfo.companyName ? userInfo.wallet?.totalCommission : userInfo.wallet?.temporaryBalance}</h3>
        </div>
        <div className="eachDash">
          <div>
            <AiOutlineCar size={20} /> Order count
          </div>
          <h3>0</h3>
        </div>
        <div className="eachDash">
          <div className="spaceB">
            <div>
              <VscReferences size={20} /> {linkText}
            </div>
            <div onClick={copyReferralLink}>
              <AiOutlineCopy className="stacked" />
              <span className="extraSmall">copy link</span>
            </div>
          </div>
          <p className="smallest">{abbreviatedReferralURL}</p>
          <QRCode value={referralURL} className="singleDash" size={80} />
        </div>
      </div>
      <div className="moneyCont">
        <Link to="/withdrawal" className="moneyCont1">
          <h4>Make a Withdrawal</h4>
        </Link>
        <Link to="/deposit" className="moneyCont2">
          <h4>Make a Deposit</h4>
        </Link>
      </div>
      <div className="middleDash">
        <FcScatterPlot size={100} />
        <p>No plot to show yet.</p>
      </div>
      <div className="middleDash">
        <h3>Transaction History</h3>
        <FcScatterPlot size={100} />
        <p>You have not completed any Transaction</p>
      </div>
      <DeleteAccountAndChats />
    </div>
  );
}
