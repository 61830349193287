import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "../styles/EditStore.css"; // Import your CSS file for styling

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const EditStore = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [storeData, setStoreData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [storeHours, setStoreHours] = useState([
    { day: "", openingTime: "", closingTime: "" },
  ]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  useEffect(() => {
    const fetchStoreData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("/api/v1/store/profile", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setStoreData(response.data);
        setStoreHours(
          response.data.storeHours || [
            { day: "", openingTime: "", closingTime: "" },
          ]
        );
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching store data:", error);
        toastr.error("Failed to load store data. Please try again.");
        setIsLoading(false);
      }
    };

    fetchStoreData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      let formData = new FormData();
      formData.append("storeName", storeData.storeName);
      formData.append("storeType", storeData.storeType);
      formData.append("storeAddress", storeData.storeAddress);
      formData.append("city", storeData.city);
      formData.append("username", storeData.username);
      formData.append("phoneNumber", storeData.phoneNumber);
      formData.append("avatar", avatar ? avatar[0] : storeData.avatar);
      formData.append(
        "storeHours",
        JSON.stringify(storeHours.map(convertTimeTo24Hours))
      );

      const response = await axios.put("/api/v1/store/profile", formData, {
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastr.success("Store details updated successfully!");
      setIsLoading(false);
      navigate("/myprofile");
    } catch (error) {
      console.error("Error updating store details:", error);
      toastr.error("Failed to update store details. Please try again.");
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setAvatar(e.target.files);
  };

  const handleStoreHoursChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStoreHours = [...storeHours];
    updatedStoreHours[index][name] = value;
    setStoreHours(updatedStoreHours);
  };

  const addStoreHour = () => {
    setStoreHours([
      ...storeHours,
      { day: "", openingTime: "", closingTime: "" },
    ]);
  };

  const removeStoreHour = (index) => {
    const updatedStoreHours = storeHours.filter((_, i) => i !== index);
    setStoreHours(updatedStoreHours);
  };

  const convertTimeTo24Hours = (hour) => {
    if (!hour.openingTime || !hour.closingTime) return hour;
    const openingTime24 = new Date(`2000-01-01T${hour.openingTime}`);
    const closingTime24 = new Date(`2000-01-01T${hour.closingTime}`);
    return {
      ...hour,
      openingTime: openingTime24.toTimeString().slice(0, 5),
      closingTime: closingTime24.toTimeString().slice(0, 5),
    };
  };

  return (
    <div className="edit-store">
      <div
        className="loading-overlay"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={isLoading}
        />
        <p>Loading...</p>
      </div>
      <form onSubmit={handleSubmit} className="edit-store-form">
        <h2>Edit store Profile</h2>
        <div className="form-group">
          <label>Store Name</label>
          <input
            type="text"
            name="storeName"
            value={storeData.storeName || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Store Type</label>
          <select
            name="storeType"
            value={storeData.storeType || ""}
            onChange={handleInputChange}
            className="form-control"
          >
            <option value="">Select Store Type</option>
            <option value="Pharmacy">Pharmacy</option>
            <option value="Supermarket">Supermarket</option>
            <option value="Agrochemical Store">Agrochemical Store</option>
            <option value="Groceries Store">Groceries Store</option>
          </select>
        </div>
        <div className="form-group">
          <label>Store Address</label>
          <input
            type="text"
            name="storeAddress"
            value={storeData.storeAddress || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            name="city"
            value={storeData.city || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={storeData.username || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={storeData.phoneNumber || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Display Image</label>
          <input
            type="file"
            onChange={handleFileChange}
            className="custom-file-input"
            accept="image/*"
          />
          <div className="image-preview-container">
            {avatar
              ? Array.from(avatar).map((image, index) => (
                  <div key={index} className="image-preview">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Image ${index}`}
                    />
                  </div>
                ))
              : storeData.avatar && (
                  <div className="image-preview">
                    <img src={storeData.avatar} alt="Store Avatar" />
                  </div>
                )}
          </div>
        </div>
        <div className="form-group">
          <label>Store Hours</label>
          {storeHours.map((hour, index) => (
            <div key={index} className="store-hour">
              <select
                name="day"
                value={hour.day}
                onChange={(e) => handleStoreHoursChange(index, e)}
              >
                <option value="" disabled>
                  Select Day
                </option>
                {daysOfWeek.map((day, idx) => (
                  <option key={idx} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <input
                type="time"
                name="openingTime"
                value={hour.openingTime}
                onChange={(e) => handleStoreHoursChange(index, e)}
              />
              <input
                type="time"
                name="closingTime"
                value={hour.closingTime}
                onChange={(e) => handleStoreHoursChange(index, e)}
              />
              <button
                type="button"
                onClick={() => removeStoreHour(index)}
                className="remove"
              >
                Remove
              </button>
            </div>
          ))}
          <button type="button" onClick={addStoreHour} className="addhour">
            Add Store Hour
          </button>
        </div>
        <button type="submit" disabled={isLoading} className="updatestore">
          {isLoading ? (
            <span className="loading-spinner"></span>
          ) : (
            "Update Store"
          )}
        </button>
      </form>
    </div>
  );
};

export default EditStore;
