import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import glogo from "../static/FARMY EMB green..png";
import grain from "../static/grain.png";
import tom from "../static/tom.png";
import money from "../static/stor2.jpg";
import comp from "../static/store1.jpg";
import logistics from "../static/log.jpg";
import lfarm from "../static/lfarm.jpg";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { IoLogoAndroid } from "react-icons/io";
import {
  AiFillApple,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";

import "../styles/landingPage.css";
import FMFooter from "../Components/FMFooter";
import Footer from "../Components/Footer";
import googleicon from "../static//googleicon.svg";
import appleicon from "../static//appleicon.svg";
import Header from "../Components/Header";
import herobg from "../static/herobg.png";
import herbgSmall from "../static/lfarm.jpg";
import orderFromFarm from "../static/orderFromFarm.png";
import scheduleOrder from "../static/scheduleOrder.png";
import chooseLogistics from "../static/chooseLogistics.png";
import servicesbg from "../static/servicesbg.png";
import orderFromFarmSvg from "../static/orderFromFarm.svg";
import scheduleOrderSvg from "../static/scheduleOrder.svg";
import chooseLogisticsSvg from "../static/chooseLogistics.svg";
import registerStore from "../static/stor2.jpg";
import wholesaleCompany from "../static/store1.jpg";
import charlie from "../static/charlie.png";
import luke from "../static/luke.png";
import roderick from "../static/roderick.png";
import scott from "../static/scott.png";
import brandi from "../static/brandi.png";
import phoneLg from "../static/phone1.png";
import phoneSm from "../static/phone2.png";
import logo from "../static/logo.png";
import farmersmarketicon from "../static/farmersmarket.svg";
import logisticsicon from "../static/logisticsicon.svg";
import wholesellericon from "../static/wholesellers.svg";
import storeicon from "../static/storeicon.svg";
import locationicon from "../static/locationicon.svg";
import contacticon from "../static/contacticon.svg";
import emailicon from "../static/emailicon.svg";
import { WindowHeight, WindowWidth } from "../utils/Dimensions";
import DownloadButton from "../Components/DownloadButton";
import { BiLogoFacebookSquare, BiLogoLinkedinSquare } from "react-icons/bi";

const servicesData = [
  {
    image: scheduleOrder,
    svg: scheduleOrderSvg,
    title: "Schedule Order",
    description:
      "We know how busy your day can be fit perfectly into your busy routine",
  },
  {
    image: orderFromFarm,
    svg: orderFromFarmSvg,
    title: "Order From Fram",
    description:
      "Order bulk agricultural products directly from the farm Before they are harvested with FarmyApp",
  },
  {
    image: chooseLogistics,
    svg: chooseLogisticsSvg,
    title: "Choose Logistics",
    description:
      "Enjoy nation wide delivery to Send and receive items nation wide with FarmyApp",
  },
];

const testimonialsData = [
  {
    name: "Charlie Leuschke",
    avatar: charlie,
    rating: 5,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
  {
    name: "Luke Glover",
    avatar: luke,
    rating: 5,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
  {
    name: "Roderick Schumm",
    avatar: roderick,
    rating: 5,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
  {
    name: "Roderick Schumm",
    avatar: roderick,
    rating: 3.2,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
  {
    name: "Brandi Boyle",
    avatar: brandi,
    rating: 4.5,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
  {
    name: "Scott Runolfsdottir",
    avatar: scott,
    rating: 4.7,
    description:
      "Phasellus fermentum orci non nunc fermentum mattis. In eleifend vehicula justo, sed pulvinar erat scelerisque vel. Vestibulum eu erat elit. Etiam mattis feugiat finibus.",
  },
];

const exploreData = [
  {
    title: "Search Store",
    icon: storeicon,
    route: "/stores",
  },
  {
    title: "Whole sellers",
    icon: wholesellericon,
    route: "/companies",
  },
  {
    title: "Farmers' Market",
    icon: farmersmarketicon,
    route: "/farmersmarket",
  },
  {
    title: "Logistics",
    icon: logisticsicon,
    route: "/logistics",
  },
];
const contactData = [
  {
    title: "+2348110175793",
    icon: contacticon,
    route: "",
  },
  {
    title: "farmapp@gmail.com",
    icon: emailicon,
    route: "",
  },

  {
    title: "Ibadan",
    icon: locationicon,
    route: "",
  },
];

function Landingpage() {
  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const imageRef = useRef(null);
  const [imgHeight, setImgHeight] = useState(0);
  // const imageHeight =

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };

  useEffect(() => {
    setImgHeight(imageRef?.current?.clientHeight);
  }, [imageRef, window.innerWidth]);
  return (
    <div className="">
      <Header />
      <div className="">
        <div>
          <section className="hero-section">
            <div className="overlay"></div>
            <img
              src={herobg}
              ref={imageRef}
              className="hero-image"
              alt="Hero background"
            />
            <div style={{ height: imgHeight }} className="content-container">
              <div className="text-container">
                <p className="hero-text">
                  Buy fresh <span className="highlight">Agricultural</span>
                  <br />
                  Produce from Farmers <br /> and Stores.
                </p>

                <div className="button-container">
                  <DownloadButton
                    icon={appleicon}
                    title1="Download on the"
                    title2="App Store"
                  />
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.paade.farmyapp" ||
                      "#"
                    }
                    target={
                      "https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                        ? "_blank"
                        : "_self"
                    }
                    rel="noopener noreferrer"
                    className="download-button__link"
                    onClick={(e) => {
                      if (
                        !"https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                      ) {
                        e.preventDefault();
                        console.warn("No URL provided for this button.");
                      }
                    }}
                  >
                    <DownloadButton
                      icon={googleicon}
                      title1="Get it on the"
                      title2="Google Play"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          <section className="services-section">
            <img
              src={servicesbg}
              className="services-background"
              alt="Services background"
            />
            <div className="services-content">
              <div className="services-container">
                <p className="services-title">Our Services</p>
                <p className="services-subtitle">What We Offer</p>
                <div className="services-items">
                  {servicesData.map((item, index) => (
                    <div key={index} className="service-card">
                      <div className="service-card-image">
                        <img
                          src={item.image}
                          alt={`${item.title} image`}
                          className="service-image"
                        />
                        <img
                          src={item.svg}
                          alt={`${item.title} icon`}
                          className="service-icon"
                        />
                      </div>
                      <p className="service-card-title">{item.title}</p>
                      <p className="service-card-description">
                        {item.description}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="register-grocery-store">
            <div className="store-image-container">
              <img
                src={registerStore}
                alt="Register Groceries Store"
                className="store-image"
              />
            </div>
            <div className="store-info">
              <p className="store-title">
                Register your <span className="highlight">Groceries</span> Store
                with Us
              </p>
              <p className="store-description">
                Partner with us and enjoy the Joy of fast sales. We Offer 24/7
                support to help you increase your daily sales and double your
                income when you register your store with us.
              </p>
              <button className="register-button1">
                <NavLink to="/signup">Create account</NavLink>
              </button>
            </div>
          </section>
          <section className="register-wholesale-company">
            <div className="store-info">
              <p className="store-title">
                Register your <span className="highlight">Food Wholesales</span>{" "}
                Company with us
              </p>
              <p className="store-description">
                Partner with us and enjoy the Joy of fast sales. We Offer 24/7
                support to help you increase your daily sales and double your
                income when you register your store with us.
              </p>
              <button className="register-button1">
                <NavLink to="/signup">Create account</NavLink>
              </button>
            </div>
            <div className="store-image-container">
              <img
                src={wholesaleCompany}
                alt="Register food wholesale company"
                className="store-image"
              />
            </div>
          </section>
          <section className="joinus-section">
            <div className="overlay"></div>
            <div className="content">
              <p className="title">Register as a Logistics Company</p>
              <p className="subtitle">
                Join us today and make multiple income streams
              </p>
              <button className="join-button1">
                <NavLink to="/signup">Join Us Now</NavLink>
              </button>
            </div>
          </section>
          <section className="customer-section-container">
            <div className="customer-section-inner">
              <div className="customer-image-wrapper">
                <img
                  src={herbgSmall}
                  alt="Register food wholesale company"
                  className="customer-section-image"
                />
              </div>
              <div className="customer-content-wrapper">
                <p className="customer-title">
                  Become Our Favourite <br />
                  <span className="customer-highlight">Customer</span>
                </p>
                <p className="customer-description">
                  Become our Favourite customer to make more <br />
                  money by becoming a referral on my farmApp
                </p>
                <button className="customer-button">
                  <NavLink to="/signup" className="customer-link">
                    <p>Create Account</p>
                  </NavLink>
                </button>
              </div>
            </div>
          </section>
          <section className="download-section-container">
            <div className="download-section-inner">
              <div className="download-text-content">
                <p className="download-title">Download the FarmApp</p>
                <div className="download-button-group">
                  <DownloadButton
                    icon={appleicon}
                    title1="Download on the"
                    title2="App Store"
                  />
                  <a
                    href={
                      "https://play.google.com/store/apps/details?id=com.paade.farmyapp" ||
                      "#"
                    }
                    target={
                      "https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                        ? "_blank"
                        : "_self"
                    }
                    rel="noopener noreferrer"
                    className="download-button__link"
                    onClick={(e) => {
                      if (
                        !"https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                      ) {
                        e.preventDefault();
                        console.warn("No URL provided for this button.");
                      }
                    }}
                  >
                    <DownloadButton
                      icon={googleicon}
                      title1="Get it on the"
                      title2="Google Play"
                      link="https://play.google.com/store/apps/details?id=com.paade.farmyapp"
                    />
                  </a>
                </div>
              </div>
              <div className="download-image-container">
                <div className="phone-large">
                  <img
                    src={phoneLg}
                    alt="Phone full image"
                    className="phone-lg-image"
                  />
                </div>
                <div className="phone-small">
                  <img
                    src={phoneSm}
                    alt="Phone half-height image"
                    className="phone-sm-image"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="footer-section-container">
            <div className="footer-content">
              {/* Left content */}
              <div className="footer-left-content">
                <div className="footer-logo">
                  <img
                    src={logo}
                    className="footer-logo-img"
                    alt="FarmyApp Logo"
                  />
                </div>
                <p className="footer-description">
                  There are many variations of passages <br /> of lorem ipsum
                  available, but the <br /> majority suffered.
                </p>
                {/* Socials */}
                <div className="footer-socials">
                  <a
                    href="https://www.facebook.com/FarmyAppAgriculture"
                    className="social-icon"
                  >
                    <BiLogoFacebookSquare color="white" size={20} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/farmyapp/?viewAsMember=true"
                    className="social-icon"
                  >
                    <BiLogoLinkedinSquare color="white" size={18} />
                  </a>
                  <a
                    href="https://instagram.com/farmyapp?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                    className="social-icon"
                  >
                    <AiFillInstagram color="white" size={18} />
                  </a>
                  <a
                    href="https://twitter.com/farmyapp?t=Hn0NSoQsuFFiUXiGuYBprw&s=08"
                    className="social-icon"
                  >
                    <AiFillTwitterSquare color="white" size={18} />
                  </a>
                </div>
              </div>
              {/* Middle content */}
              <div className="footer-middle-content">
                <div className="section-title">
                  <p className="section-title-text">Explore</p>
                  <div className="title-decorator">
                    <div className="decor-line"></div>
                    <div className="decor-circle"></div>
                  </div>
                </div>
                <div className="explore-links">
                  {exploreData.map((item, index) => (
                    <NavLink
                      key={index}
                      className="explore-link"
                      to={item.route}
                    >
                      <img src={item.icon} alt={`${item.title} icon`} />
                      <p className="link-text">{item.title}</p>
                    </NavLink>
                  ))}
                </div>
              </div>
              {/* Right content */}
              <div className="footer-right-content">
                <div className="section-title">
                  <p className="section-title-text">Contact</p>
                  <div className="title-decorator">
                    <div className="decor-line"></div>
                    <div className="decor-circle"></div>
                  </div>
                </div>
                <div className="contact-links">
                  {contactData.map((item, index) => (
                    <NavLink key={index} className="contact-link">
                      <img src={item.icon} alt={`${item.title} icon`} />
                      <p className="link-text">{item.title}</p>
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            {/* Footer note */}
            <div className="footer-note">
              <div className="footer-note-content">
                <p>© All Copyright 2024 by FarmyApp</p>
                <div className="footer-terms">
                  <NavLink to="/tandc" className="terms-link">
                    Terms of Use
                  </NavLink>
                  {" | "}
                  <NavLink to="/faqs" className="terms-link">
                    FAQs
                  </NavLink>
                  {" | "}
                  <NavLink to="/referral" className="terms-link">
                    Referral Benefits
                  </NavLink>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
